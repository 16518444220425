<template>
  <section id="dashboard-analytics">
    <b-row style="margin-top: 10px">
      <b-col cols="12">
        <reported-users-list />
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ReportedUsersList from '@/views/amic-views/components/users/ReportedUsersLit.vue'


import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BRow,
    BCol,
    ReportedUsersList,
    BCardActions,
  },
  methods: {
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },
  },
  data() {
    return {
    }
  },
  created() {
    // data
    /* this.$http.get('/analytics/data')
      .then(response => { this.data = response.data }) */
  },
  methods: {
    kFormatter,
  },
}
</script>
