<template>
  <div>
    <b-modal
      id="modal-ban"
      cancel-variant="outline-secondary"
      ok-title="Ban and archive report"
      cancel-title="Cancel"
      centered
      @ok="banAndArchiveReport(modalReport)"
      :title="
        'Select ban reason for ' +
        modalReport.username +
        '(' +
        modalReport.id +
        ')'
      "
    >
      <b-form>
        <b-form-group label="">
          <b-form-checkbox-group
            v-model="selectedBanOptions"
            :options="banOptions"
            name="flavour-2a"
            stacked
            class="demo-vertical-spacing"
          ></b-form-checkbox-group>
        </b-form-group>
        <b-form-input id="banReasonInput" placeholder="Other reason " />
      </b-form>
    </b-modal>
    <b-card no-body class="mb-0" style="min-height: 500px;">
      <div class="m-2">
        <!--b-table striped-->
        <div class="text-center" v-if="reportedUsers.length == 0">
          <b-spinner variant="primary" label="Text Centered" />
        </div>
        <b-table
          v-else
          class="position-relative"
          style="min-height: 300px"
          :items="reportedUsers"
          responsive
          :fields="fields"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(details)="row" style="max-width: 10px">
            <feather-icon
              :icon="row.detailsShowing ? 'Minimize2Icon' : 'Maximize2Icon'"
              @click="row.toggleDetails"
              href=""
              class="text-info cursor-pointer"
              badge-classes="badge-info"
              size="21"
            />

            <!--  <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
           {{ row.detailsShowing ? 'Hide report' : 'Show report' }}
          </b-button> -->
            <!-- <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails"
        > ArrowDownIcon ArrowUpIcon
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? 'Hide' : 'Show' }}</span>
        </b-form-checkbox> -->
          </template>

          <!-- expandable item for each row -->
          <template #row-details="row">
            <b-card class="border mb-0">
              <b-row class="mb-2">
                <b-col md="4" class="mb-1">
                  <b-avatar
                    :src="row.item.avatar"
                    :text="avatarText(row.item.fullName)"
                    variant="light-warning"
                    size="400px"
                    rounded
                  />
                </b-col>

                <b-col md="8" class="mb-1">
                  <b-row class="mb-2">
                    <b-col md="6" class="mb-1">
                      <strong>Bio: </strong>{{ row.item.bio }}
                    </b-col>
                    <b-col md="6" class="mb-1">
                      <strong>Birthday: </strong>{{ row.item.birthday }}
                    </b-col>
                    <b-col md="6" class="mb-1">
                      <strong>Sex: </strong>{{ row.item.sex }}
                    </b-col>
                    <b-col md="6" class="mb-1">
                      <strong>Country: </strong>{{ row.item.country }}
                    </b-col>
                    <b-col md="6" class="mb-1">
                      <strong>City: </strong>{{ row.item.city }}
                    </b-col>
                    <b-col md="6" class="mb-1">
                      <strong>Email (profile): </strong>{{ row.item.email2 }}
                    </b-col>
                    <b-col md="6" class="mb-1">
                      <strong>Phone (profile): </strong>{{ row.item.phone2 }}
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="4" class="mb-1">
                  <strong>Number of reports: </strong
                  >{{ row.item.numberOfReports }}
                </b-col>
                <!-- Global reports information -->
                <b-col md="2" class="mb-1">
                  <strong>Reported For: </strong>
                  <div v-for="reason in row.item.reasonsGlobal" :key="reason">
                    <div>
                      <b-row class="mb-2">
                        {{ getReportReason(reason.reason) }}
                        <strong
                          :class="getNumberOfReportsClass(reason.occurrence)"
                        >
                          &nbsp; x{{ reason.occurrence }}
                        </strong>
                      </b-row>
                    </div>
                  </div>
                </b-col>
                <!-- Each report individually -->
                <b-col
                  md="4"
                  class="mb-1"
                  style="position: relative; height: 300px; overflow-y: scroll"
                >
                  <strong>Reports details: </strong>
                  <div v-for="reason in row.item.reasons" :key="reason">
                    <div>
                      <b-row class="border mb-2">
                        <b-col>
                          {{ getReportReason(reason.reason) }}
                        </b-col>
                        <b-col>
                          {{ reason.information }}
                        </b-col>
                        <b-col>
                          <b-link
                            :to="{
                              name: 'amic-pages-profile',
                              params: { id: reason.reportedBy },
                            }"
                            class="font-weight-bold d-block text-nowrap"
                          >
                            {{ reason.reportedBy }}
                          </b-link>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide report
              </b-button>
            </b-card>
          </template>

          <!--template #cell(user)="row">
        <!-- <b-avatar :src="data.value" /> >
        <b-media vertical-align="center">
          <b-avatar
            size="32"
            :src="row.item.avatar"
            :text="avatarText(row.item.fullName)"
          />
          <small class="text-muted">@{{ row.item.username }}</small>
        </b-media>
      </template-->

          <template #cell(user)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="data.item.avatarThumbnail"
                  :text="avatarText(data.item.fullName)"
                  :variant="status[1][data.value]"
                  :to="{
                    name: 'amic-pages-profile',
                    params: { id: data.item.id },
                  }"
                />
              </template>
              <b-link
                :to="{
                  name: 'amic-pages-profile',
                  params: { id: data.item.id },
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.fullName }}
              </b-link>
              <small class="text-muted">@{{ data.item.username }}</small>
            </b-media>
          </template>

          <template #cell(emailVerified)="data">
            <div v-if="data.item.emailVerified != 'no-email'">
              <b-badge
                variant="light-success"
                v-if="data.item.emailVerified == true"
              >
                Verified
              </b-badge>
              <b-badge variant="light-warning" v-else> Not verified </b-badge>
            </div>
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Show profile</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-b-modal.modal-ban
                @click="setCurrentBanModalFor(data.item)"
              >
                <!--  <b-dropdown-item
              v-b-modal.modal-login
                @click="showBanDialog(data.item.id, data.item.username)"
              > -->
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Ban permanently</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'apps-users-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Ban temporarly</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="
                  deleteReportDialog(data.item.reportId, data.item.username)
                "
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete report</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>
      <div v-if="reportedUsers.length > 0">
      <div class="m-2 text-center" v-if="noMoreData == false">
        <b-button
          variant="primary"
          disabled
          class="mr-1"
          v-if="isLoading == true"
          @click="cancelLoadMore"
        >
          <b-spinner small />
          Loading...
        </b-button>
        <b-button variant="primary" class="mr-1" @click="loadMore" v-else>
          Load more
        </b-button>
      </div>
      <div class="m-2 text-center" v-else>
        <b-button variant="primary" disabled class="mr-1" @click="loadMore">
          No more data
        </b-button>
      </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable, BFormCheckbox, BButton, BCard, BRow, BCol, BAvatar, BBadge, BMedia, BLink,
  BDropdown, BDropdownItem, BSpinner, BImg,
  BFormGroup, BFormCheckboxGroup, BCardText, BFormInput, BModal, BForm
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { getReportedUsers, banReportedUser } from '@/amic-js/reports'
import { getReportReason, getReasonsWithOccurences } from '@/amic-js/reportutils'
import { getSmallThumbnail, getMediumThumbnail } from '@/amic-js/stringutils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BMedia,
    BLink,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    BFormGroup,
    BFormCheckboxGroup,
    BCardText,
    BFormInput,
    BModal,
    BForm,
  },
  data() {
    return {
      modalReport: {},
      selectedBanOptions: [],
      banOptions: [
        { text: getReportReason(0), value: '0' },
        { text: getReportReason(1), value: '1' },
        { text: getReportReason(2), value: '2' },
        { text: getReportReason(3), value: '3' },
        { text: getReportReason(4), value: '4' }
      ],
      limitPerPage: 10,
      skip: 0,
      noMoreData: false,
      isLoading: false,
      fields: [{ key: 'details', class: 'limied-width-column' }, { key: 'id', sortable: true }, { key: 'user', sortable: true }, { key: 'email', sortable: true }, 'phone', { key: 'emailVerified', label: 'Email Verified', sortable: true }, 'authProvider', 'createdAt', 'plan', 'actions'],
      reportedUsers: [
      ],
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  methods: {
    getReportedUsers,
    banReportedUser,
    getReasonsWithOccurences,
    getSmallThumbnail,
    getMediumThumbnail,
    getReportReason,
    loadMore() {
      this.isLoading = true;
      getReportedUsers(this.skip, this.limitPerPage).then((reports) => {
        this.isLoading = false;
        this.skip = this.skip + reports.length;
        if (reports.length == 0) {
          this.noMoreData = true;
        }
        else {
          reports.forEach(report => {
            const reportedUser = report.get("user")
            var userImageURL = ""
            var userImageThumbnailURL = ""
            if (reportedUser.get("userImage")) {
              userImageURL = reportedUser.get("userImage").url();
              userImageThumbnailURL = getMediumThumbnail(userImageURL);
            }
            var isEmailVerified;
            if (reportedUser.get("emailVerified") === undefined) {
              isEmailVerified = 'no-email'
            }
            else {
              isEmailVerified = reportedUser.get("emailVerified")
            }

            var createdDate = reportedUser.get("createdAt").toISOString().slice(0, 10)
            var createdTime = reportedUser.get("createdAt").toISOString().slice(11, (reportedUser.get("createdAt").toISOString().length) - 5)

            const reasons = report.get("reasons") ? report.get("reasons") : []
            const reasonsGlobal = report.get("reasons") ? getReasonsWithOccurences(report.get("reasons"), "reason") : []
            const numberOfReports = report.get("reasons") ? report.get("reasons").length : 0

            const newUser = {
              reportObject: report,
              parseUser: reportedUser,
              reportId: report.id,
              id: reportedUser.id,
              numberOfReports: numberOfReports,
              reasonsGlobal: reasonsGlobal,
              reasons: reasons,
              avatar: userImageURL,
              avatarThumbnail: userImageThumbnailURL,
              createdAt: createdDate + "@" + createdTime,
              username: reportedUser.get("username"),
              fullName: reportedUser.get("firstName") + " " + reportedUser.get("lastName"),
              bio: reportedUser.get("bio"),
              post: 'Nuclear Power Engineer',
              plan: isEmailVerified,
              email: reportedUser.get("email") ? reportedUser.get("email") : "",
              emailVerified: isEmailVerified,
              phone: reportedUser.get("phone") ? '+' + reportedUser.get("phone") : "",
              authProvider: reportedUser.get("authData") ? reportedUser.get("authData")[0] : '',

              birthday: reportedUser.get("userInformation").get("userInformationBirthday").get("birthday").toISOString().slice(0, 10),
              sex: reportedUser.get("userInformation").get("userInformationSex").get("sex"),
              country: reportedUser.get("userInformation").get("userInformationCountry").get("country"),
              city: reportedUser.get("userInformation").get("userInformationCity").get("city"),
              email2: reportedUser.get("userInformation").get("userInformationEmail").get("email"),
              phone2: reportedUser.get("userInformation").get("userInformationPhone").get("phone"),


              status: 1,
            };
            this.reportedUsers.push(newUser)
          });
        }
      },
        (error) => {
          this.isLoading = false;
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list ' + error.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
            {
              timeout: false,
            })
        });

    },
    cancelLoadMore() {
      this.isLoading = false;
    },
    setCurrentBanModalFor(item) {
      this.modalReport = item
    },
    banAndArchiveReport(item) {
      const banResult = banReportedUser(item.reportObject, item.parseUser)
      if (banResult instanceof Promise) {
        banResult.then((user) => {
          const index = this.reportedUsers.indexOf(item);
          if (index > -1) {
            this.reportedUsers.splice(index, 1);
          }
          this.toast({
            component: ToastificationContent,
            props: {
              title: item.parseUser.get("username") + ' has been banned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
            {
              timeout: false,
            })

        }, (error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error the report has beedn archived but failed to ban the user ' + error.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
            {
              timeout: false,
            })
        })
      }
      else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list ' + banResult.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
          {
            timeout: false,
          })
      }

    },
    showBanDialog(id, username) {
      this.$swal({
        title: 'Are you sure?',
        text: username + " with id " + id + " will be banned",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Permanent Ban',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: username + ' with id: ' + id + ' has been banned',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    deleteReportDialog(id, username) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you usre you want to delete the report (' + id + ") for " + username,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete report',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: id + ' report for user: ' + username + ' has been deleted',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    getNumberOfReportsClass(n) {
      if (n < 10) {
        return "text-info"
      }
      else if (n > 10 && n < 20) {
        return "text-warning"
      }
      else if (n > 20) {
        return "text-danger"
      }
    }
  },
  setup() {
    const toast = useToast();
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    return { toast, sortBy, avatarText, isSortDirDesc }
  },
  mounted() {
    this.loadMore()
  }
}
</script>
<style  >
.limied-width-column {
  max-width: 50px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>